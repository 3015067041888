<template>
  <div class="main">
    <Header />
    <slot />
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import Header from '@/components/sections/Header.vue'

export default defineComponent({
  name: 'main-template',
  components: {
    Header
  }
})
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
}
</style>
